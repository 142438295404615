@import '../../../Variables.scss';
@import '../../../CommonStyle.scss';
// color icon #0078D7 / #C7E0F4

.column.is-narrow:not(.action-button) {
    padding: unset;
}

.action-button {
    @extend .noselect-text;
    
    cursor: pointer;
    padding: 0.6rem 1rem;

    i {
        color: $action-color-icon;
        margin-right: 8px;
    }

    &:hover:not(.is-disable) {
        //background-color: #DEECF9;
        background-color: #C7E0F4;
    }

    &.is-disable{
        cursor: not-allowed;
        color: $disable-color;

        i {
            color: $disable-color;
        }
    } 
    
    .action-dropdown {
        i:last-child {
            margin-left: 8px;
            margin-right: unset;
        }
    }

    .dropdown-menu {
        width: unset !important;  

        .dropdown-item {
            span {
                white-space: nowrap;
            }
        }
    }
}