@import '../../../Variables.scss';
@import '../../../CommonStyle.scss';

.left-navbar {
    height: 100%;

    .left-navbar-search{
        width: 100%;
        min-height: $action-menu-height;
        background-color: #DEECF9;

        p.control {
            &:first-child {
                width: 100%;
            }

            input, span.button {
                border: none;
                background-color: inherit;
                box-shadow: unset;
            }

            input:focus ~ .icon:not(.reset-button) {
                color: $action-color-icon;
            }            

            span:not(.reset-button) {
                color: $action-color-icon;
            }

            input:focus ~ .icon.reset-button {
                color: $font-color;
            }

            span.reset-button {
                color: $font-color;
                cursor: pointer;

                &:hover {
                    color: $action-color-icon;
                }
            }
        }        
    }

    .left-navbar-tree {
        @extend .noselect-text;
        
        height: calc(100% - #{$action-menu-height});
        padding: 0.75rem 0;
        display: flex;

        .loading-container {
            margin: auto;
        }

        .left-navbar-scrollbar {
            width: 100%
        }
    }
}
