@import "../../Variables.scss";

.app-button {    
    display: flex;
    min-width: 200px;
    min-height: 200px;
    background-color: $primary-color;

    color: white;
    font-size: 22px;
    font-weight: 700;
}

.app-button > div{
    display: flex;
    width: 100%;
}

.app-button > div:hover {
    background-color:rgba(0,0,0,0.1);
}