@import '../../../Variables.scss';
@import '../../../CommonStyle.scss';

.my-table-with-filter {
    position: relative;
    min-height: 15vh;

    .my-table-filter-header {
        @extend .noselect-text;

        margin: 0 1em 1em 1em;
        padding: 1em 0;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid $border-color;

        & p {
            margin: 0;
        }

        & i {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;                

            &:before {
                margin: auto; 
                
                -moz-transition: transform 0.3s ease-out;
                -webkit-transition: transform 0.3s ease-out;
                -o-transition: transform 0.3s ease-out;
                transition: transform 0.3s ease-out;
            }
        }   
        
        &.active-filter i:before {
            transform: rotate(90deg);
        }
    }    

    .my-table-filter {
        @extend .noselect-text;
        
        margin-bottom: 3vh;              

        &:not(.active-filter) > form {
            display: none;
        }

        .filter {
            display: flex;
            flex-wrap: wrap;

            .filter-field {
                padding: 1em;
                min-width: 350px;
                width: 20%;

                .input {
                    padding-left: calc(0.625em - 4px);
                    padding-right: calc(0.625em + 8px);
                }
                
                @media (max-width: $mobile) {
                    width: 100%;
                }

                .filter-label {
                    color: $light-font-color;
                }
            }

            .filter-input {
                height: 2.25em;
            }
        }

        .buttons {
            padding-top: 1em;
            padding-right: 1em;
        }
    }

    .my-table {
        background-color: $background-color;
    }
}
.credit {
    padding-left: 2em;
    margin-top: -2em;
}