@import '../../../Variables.scss';

.filter-field {

    .MultiSelect {
        & > div:first-child {
            height: 2.25em;            

            & > div {
                height: 100%;
                min-height: unset;

                & > * {
                    height: 100%;
                    line-height: 2.25em;
                }
            }
        }
    }

    .date-range-field {
        .field {
            & > div {
                display: inline-block;
                width: 49%;
            }

            &.with-time > div {
                @media screen and (min-width: $tablet) {
                    width: 100%;                    
                }
            }

            & .range-separator {
                width: 2%;
            }
        }
    }

    .input-with-reset-button {
        position: relative;

        &:focus {
            outline: none;
        }

        input[value=""] + div.reset-button {
            display: none;
        }
        
        & div.reset-button {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            display: flex;
            padding: 0 5px;
            color: $disable-color;
            cursor: pointer;

            &:hover {
                color: $primary-color;
            }

            &:before {
                content: "✕";
                margin: auto;
            }
        }
    }    
}

.my-calendar {
    &, & * {
        border-radius: 0 !important;
    }

    .without-second {
        .rc-time-picker-panel-select {
            width: 126px;
        }
    }

    .rc-calendar-selected-day {
        .rc-calendar-date {
            background-color: transparent;
        }
    }

    .rc-calendar-selected-date {
        .rc-calendar-date {
            background-color: $primary-color !important;
        }
    }  

    .rc-calendar-today {
        .rc-calendar-date{
            border-color: $primary-color;
        }
    } 

    .rc-calendar-month-panel-selected-cell,
    .rc-calendar-year-panel-selected-cell,
    .rc-calendar-decade-panel-selected-cell { 
        a {
            background-color: $primary-color;
        }
    }

    .rc-calendar-month-panel-header,
    .rc-calendar-year-panel-header,
    .rc-calendar-decade-panel-header {
        & > a {
            color: $font-color;
            
            &:hover {
                color: $primary-color;
            }
        }
    }

    .rc-calendar-today-btn, .rc-calendar-ok-btn, .rc-calendar-time-picker-btn,
    .rc-calendar-prev-month-btn, .rc-calendar-next-month-btn, .rc-calendar-prev-year-btn, .rc-calendar-next-year-btn,
    .rc-calendar-year-select, .rc-calendar-month-select, .rc-calendar-day-select {
        color: $font-color;

        &:hover {
            color: $primary-color;
        }        
    }

    .rc-calendar-date:hover,
    .rc-calendar-year-panel-year:hover,
    .rc-calendar-month-panel-month:hover,
    .rc-calendar-decade-panel-decade:hover {
        background-color: $hover-color;
    }
}