@import '../../../Variables.scss';

$collapse-time: 0.5s;

$header-black      : #242a32;
$tab-text          : #5d5d5d;
$white			       : #fff;
$reviews-text-black: #363636;
$white-light-gray  : #f9f9f9;

@mixin user-select() {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

#aspect-content {
  margin: 50px 0 0;
}
.aspect-tab {
  border: 1px solid $border-color;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 10px;
  opacity: 1;
}
.aspect-input {
  display: none;
}
.aspect-input:checked ~ .aspect-content + .aspect-tab-content {
  max-height: 3000px;
}
.aspect-input:checked ~ .aspect-content:after {
    transform: rotate(0);
}
.aspect-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 50px;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  z-index: 1;
  cursor: pointer;
//   &:hover ~ .aspect-content:after {
//     // background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiM1NTZBRUEiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=');
//   }
}
.aspect-content {  
  position: relative;
  display: block;
  margin: 0;
  padding: 0 87px 0 30px;
  font-size: 0;
  white-space: nowrap;
  cursor: pointer;
  @include user-select();
  &:before,
  &:after  {
    content: '';
    display: inline-block;
    vertical-align: middle;
  }
  &:before {
    height: 100%;
  }
  &:after {
    position: absolute;
    width: 24px;
    height: 100%;
    right: 30px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNBOUFDQUYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    -moz-transition: transform $collapse-time ease-out;
    -webkit-transition: transform $collapse-time ease-out;
    -o-transition: transform $collapse-time ease-out;
    transition: transform $collapse-time ease-out;  
  }
}
.aspect-name {
  display: inline-block;
  width: 75%;
  margin-left: 16px;
  font-weight: 500;
  color: $font-color;
  white-space: normal;
  text-align: left;
  vertical-align: middle;

  i {
      margin-right: 10px;
      font-size: 20px;
  }
}
.aspect-stat {
  width: 40%;
  text-align: right;
}
.all-opinions,
.aspect-name {
  font-size: 14px;
  line-height: 22px;
}
.all-opinions {
  color: $tab-text;
  text-align: left;
}
.aspect-content + .aspect-tab-content {
  max-height: 0;
  overflow: hidden;
  -moz-transition: max-height 0.3 ease-out;
  -webkit-transition: max-height 0.3 ease-out;
  -o-transition: max-height 0.3 ease-out;
  transition: max-height 0.3 ease-out; 
}
.aspect-content > div,
.aspect-stat > div {
  display: inline-block;
}
.aspect-content > div {
  vertical-align: middle;
}

.aspect-info {
  width: 60%;
  white-space: nowrap;
  font-size: 0;
  &:before {
    content: '';
    display: inline-block;
    height: 44px;
    vertical-align: middle;
  }
}

.sentiment-wrapper {
    padding: 24px 30px 30px;

    p {
        margin-bottom: 5px;
    }
    div {
        width: 100%;
        color: $light-font-color;
        font-size: 14px;

        @media (max-width: $mobile) {
            margin-left: 75px;
        }
    
        @media (min-width: $tablet) {
            margin-left: 40px;
        }
    
        @media (min-width: $desktop) {
            margin-left: 50px;
        }
    
        @media (min-width: $widescreen) {
            margin-left: 60px;
        }
    
        @media (min-width: $fullhd) {
            margin-left: 75px;
        }
    }    
}

@media screen and (max-width: 800px) {
  .aspect-label {
    max-height: 102px;
  }
  .aspect-content {
    height: auto;
    padding: 10px 87px 10px 30px;
    &:before {
      display: none;
    }
    &:after {
      top: 0;
    }
  }
  .aspect-content > div {
    display: block;
    width: 100%;
  }
  .aspect-stat {
    margin-top: 10px;
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .aspect-label {
    max-height: 140px;
  }
  .aspect-stat > div {
    display: block;
    width: 100%;
  }
  .all-opinions {
    margin-bottom: 10px;
  }
  .all-opinions + div > span:first-child {
    margin: 0;
  }
}