@import "../../../Variables.scss";

.modal {
    top: $top-navbar-height !important;
    z-index: -1 !important;
    opacity: 0;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    &.modal-active {
        z-index: 25 !important;
        opacity: 1;
    }

    &.modal-delete {
        .modal-card {
            box-shadow: $card-box-shadow;
            border: 1px solid $border-color;

            .modal-card-head {
                display: block;
    
                div:first-child {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
    
                .modal-card-title {
                    text-align: center;
                }
            } 
    
            .modal-card-body {
                padding-top: 25px;
                padding-bottom: 25px;
                color: $font-color;

                * {
                    text-align: center;
                }
            }
            
            .modal-card-foot {
                display: block;
    
                .modal-buttons {                               
                    display: flex;

                    .button:not(:last-child) {
                        margin-right: 5px;
                    }
                }            
            }

            @media (min-width: $mobile) {
                width: 400px;
            }

            @media (max-width: $mobile) {
                width: 100%;
            }
        }     
    }    
}