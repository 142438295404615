@import '../../Variables.scss';
    
    .box-login {
        border: 1px rgb(200, 200, 200) solid;
        padding: 20px 50px 30px 50px;
        background-color: rgba(255, 255, 255, 0.8);  
        min-width: 475px;   

        position: absolute;
        top: 25%;
        left: calc(50% - 237px);
        margin: auto;
        
        @media (max-width: $mobile) {
            width: 100%;
            left: 0;
        }
    }

    .box-login .login-form {
        margin-top: 0px !important;
        max-width: none !important;
    }

    .input-group-addon {
        border-width: 0px !important;
        min-height: 35px !important;
        min-width: 35px !important;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px !important;
        color: rgb(200, 200, 200) !important;
        box-shadow: none;
        background: rgb(255, 255, 255) !important;
    }

    .login-form {
        h4 {
            color: rgb(180, 180, 180) !important;
        }

        .login-buttons {
            margin-top: 20px;
            display: inline-block;
            width: 100%;
        }

        input.form-control {
            border-width: 0px !important;
            box-shadow: none !important;
            background: rgb(255, 255, 255) !important;
            font-size: 14px !important;
            min-height: 35px !important;
            padding-left: 5px !important;
        }
    }

    .mt-25 {
        margin-top: 25px !important;
    }

    .mb-25 {
        margin-bottom: 25px !important;
    }

    h2 {
        color: rgb(200, 200, 200) !important;
    }

    h2 i {
        margin-right: 20px;
    }

    .lost-password-container {
        width: 67%;
        height: 40px;
        display: flex;

        a.lost-password {
            color: #6d6d6d;
            font-size: 0.95em; 
            margin: auto 0;      
        }
    
        a.lost-password:hover {
            color: $primary-color;
            text-decoration: none;
        }
    }    

    .btn-login {
        overflow-x: hidden;
        width: 33%;

        &.is-crif {
            color: white;
            background-color: rgb(238,125,17);

            &:hover {
                color: white;
            }

            &.is-loading::after {
                border: 2px solid white;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        }
    }

    .icon-lock, button i {
        margin-right: 5px;
    }

    .display-password {
        color: #666 !important;
        cursor: pointer;
    }    

    .missing-input {
        border-color: darkred !important;
    }