@import "Variables.scss";

$table-row-hover-background-color: $hover-color;
$table-row-active-color: $input-color;
$table-row-active-background-color: $selected-color;
$table-head-cell-color: #aaa;
$pagination-item-font-size: 13px;
$modal-background-background-color: rgba(255, 255, 255, 0.5);
$modal-card-head-border-bottom: none;
$modal-card-head-background-color: white;
$modal-card-foot-border-top: none;

.is-checkradio[type=checkbox].is-primary:hover:not([disabled])+label::before, 
.is-checkradio[type=checkbox].is-primary:hover:not([disabled])+label:before {
    border-radius: unset !important;
    border: 1px solid $input-border-color-hover !important;
}

.is-checkradio[type=checkbox]:checked+label::before, 
.is-checkradio[type=checkbox]:checked+label:before, 
.is-checkradio[type=radio]:checked+label::before, 
.is-checkradio[type=radio]:checked+label:before {
    border: 1px solid $input-border-color !important;
}

.is-checkradio[type=checkbox]+label::before, 
.is-checkradio[type=checkbox]+label:before, 
.is-checkradio[type=radio]+label::before, 
.is-checkradio[type=radio]+label:before {
    border: 1px solid $input-border-color !important;
}

.is-checkradio[type=checkbox]+label::before, 
.is-checkradio[type=checkbox]+label:before {
    border-radius: unset !important;
}

.is-checkradio[type=checkbox].is-primary:checked+label::after, 
.is-checkradio[type=checkbox].is-primary:checked+label:after {
    border-color: $primary-color !important;
}

.buttons {
    margin-left: 10px !important;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
    margin-right: 1.5rem;
}

.table {
    tr.is-selected {
        td {
            border-color: $input-border-color !important;
        }
    }
}