@import "../../../Variables.scss";

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid $primary-color;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 30px;
        left: 30px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}