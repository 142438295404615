@import "../../../Variables.scss";

.quickview-back{
    z-index: 28;    
    position: unset;
    top: $top-navbar-height;
    bottom: 0;
    left: 0;
    right: 0;

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;

    &.is-active {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.quickview {
    z-index: 29;
    position: absolute;
    background-color: white;
    top: 0;
    bottom: 0;
    display: flex;

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;

    @media (max-width: $mobile) {
        width: 100%;
        right: -100%;
    }

    @media (min-width: $tablet) {
        width: 75%;
        right: -75%;
    }

    @media (min-width: $desktop) {
        width: 65%;
        right: -65%;
    }

    @media (min-width: $widescreen) {
        width: 55%;
        right: -55%;
    }

    @media (min-width: $fullhd) {
        width: 40%;
        right: -40%;
    }

    .is-active &{
        right: 0;
        border-left: 1px solid $border-color;
        box-shadow: -30px 0 30px -30px rgba(0, 0, 0, 0.25);
    }
}

.quickview-fullwidth {
    width: 100%;
}

.quickview-content {
    @extend .quickview-fullwidth;
    height: 100%;
    padding: 40px 0;
}

.quickview-section {
    @extend .quickview-fullwidth;
    padding: 0 40px;
}

.quickview-header {
    @extend .quickview-section;
    color: $font-color;
    overflow: hidden;

    .quickview-button {
        padding: 0px 5px;
        float: right;
        font-size: 25px;
        cursor: pointer;

        &.quickview-close {
            i{
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
                -o-transition: all 1s ease;
                transition: all 1s ease;
            }            

            &:hover i{
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
    }
}

.quickview-body {
    @extend .quickview-fullwidth;
    height: 100%;
}