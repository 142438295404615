@import '../../../Variables.scss';
@import "../../../CommonStyle.scss";

$collapse-time: 0.2s;

.my-table {
    margin: 5px;
    position: relative;

    .table {
        width: 100%;

        &.is-selectable tbody tr {
            cursor: pointer;
        }
    }
}

.table-header-with-tooltip{
    position: relative;
    display: inline-block;
}

.table-header-with-tooltip:hover .table-header-tooltip {
    cursor: help;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

th {
    font-weight: 400 !important;
}

.loading-body {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    display: flex;
    background-color: rgba(255,255,255,0.75);
}

tbody tr {
    font-size: 13px;

    &.success {
        background-color: #F1F8E9;
    }
      
    &.warning {
        background-color: #FFF3E0;
    }

    &.error {
        background-color: #FBE9E7;
    }

    &.detail-row {
        box-shadow: inset 0px 7px 8px -8px #cacaca, inset 0px -5px 10px -8px #cacaca;

        &:hover {
            background-color: unset !important;
        }

        .table {
            thead tr:hover {
                background-color: unset !important;
            }

            & * {
                cursor: auto;
            }
        }
    }

    &.hide-row {
        display: none;
    }             

    td {
        &.collapse-cell{
            width: 32px;
            cursor: pointer;

            i {
                font-size: 19px !important;
    
                -moz-transition: transform $collapse-time ease-out;
                -webkit-transition: transform $collapse-time ease-out;
                -o-transition: transform $collapse-time ease-out;
                transition: transform $collapse-time ease-out; 
    
                &.fa-down {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
        }          
    }  

    .select-row {
        background-color: $hover-color;
    }
}

.pagination-link {
    font-weight: 700;
}

.active {
    background-color: $primary-color !important;
    color: $inverse-primary-color !important;
}

nav.level.table-title {
    margin-bottom: 0;
}

.showing-title {
    color: #aaa;
    font-size: 13px;
}

.dropdown-menu {
    @extend .noselect-text;
    min-width: unset !important;
    width: 100%;
    z-index: 4000 !important;

    .dropdown-item {
        padding-right: 1rem;
    }

    .dropdown-item:hover{        
        background-color: $dropdown-hover-color;
        cursor: pointer;
    }
}

.page-size-button {
    height: 30px !important;
    padding: 0 8px !important;
}

.page-size-dropdown *{
    font-weight: 700 !important;
}

.table-header-with-sort {
    padding-right: 18px;
}

.sort-icon {
    margin-left: 5px;
    font-size: 13px !important;
}

.clickable-header {
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}

td time:not(:first-child) {
    margin-left: 5px;
}