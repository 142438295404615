@import '../../../../Variables.scss';

$collapse-time: 0.2s;

.accordion-navbar {

    p {
        font-size: 13px;
        padding: 2px 10px 2px 0px;

        &.active-item{
            background-color: $selected-color;
        }

        .collapse-button {
            padding: 2px 10px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.02);
                cursor: pointer;
            }
        }
    }

    p:hover {
        background-color: $hover-color;
        cursor: pointer;
    }

    p i:not(:first-child) {
        margin-right: 5px;
        -moz-transition: transform $collapse-time ease-out;
        -webkit-transition: transform $collapse-time ease-out;
        -o-transition: transform $collapse-time ease-out;
        transition: transform $collapse-time ease-out;          
    }

    .fa-down {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    ul {
        margin-left: 25px;
        font-size: 14px;
        height: 0;
        transform: scaleY(0);

        li.clickable {
            &.active-item{
                background-color: $selected-color;
            }

            &:hover {
                background-color: $hover-color;
                cursor: pointer;
            }
        }
    }

    &.with-icon ul {
        margin-left: 30px;
    }

    .active-list{
        height: 100%;        
        transform: scaleY(1); 
        transform-origin: top;
        -moz-transition: transform $collapse-time ease-out;
        -webkit-transition: transform $collapse-time ease-out;
        -o-transition: transform $collapse-time ease-out;
        transition: transform $collapse-time ease-out;  
    }

    .end-item {
        margin-left: 10px;
        padding-left: 10px;

        i {
            margin-right: 5px;
        }

        &.item-with-icon {
            margin-left: 0;
        }
    }
}