@import 'node_modules/react-sm-select/dist/variables.scss';

@import '../../../Variables.scss';

$SM-borderRadius: 0;
$SM-tagBorderRadius: 0;
$SM-dropDownZIndex: 100;
$SM-borderColor: $input-border-color;
$SM-focusColor: $input-border-color-focus; 
$SM-tagColor: $inverse-primary-color;
$SM-tagCloseBackgroundColor: $border-color;
$SM-dropDownActiveArrowColor: $primary-color;
$SM-itemBackgroundHoverColor: $dropdown-hover-color;
$SM-itemBackgroundFocusColor: white;
$SM-textColor: $input-color;
$SM-valueColor: $input-color;

@import 'node_modules/react-sm-select/dist/main.scss';

.MultiSelect {
    .Header__value{
        height: unset;
        min-height: 40px;

        span {
            font-family: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
        }
    }

    .Header__tags {
        display: block;
    }

    .Header__tag {
        margin: 4px;
        float: left;
    }

    .Header__tag__close:hover {
        color: $primary-color !important;
    }

    .Header__reset {
        margin: auto;
        
        &:hover {
            color: $primary-color !important;
        }
    }

    .Header__tag__label {
        background-color: $primary-color !important; //bug - for background color of tag
    }    

    .Header {
        &:hover {
            border-color: $input-border-color-hover; 
        }

        &.Header--focusedActive {
            box-shadow: $input-border-color-focus-box-shadow;
        }        
    }

    .is-danger & .Header {
        border-color: $input-danger-border-color;

        &:hover {
            border-color: $input-danger-border-color; 
        }

        &.Header--focusedActive {
            box-shadow: $input-danger-border-color-focus-box-shadow;
        } 
    }

    .is-danger & .DropDown {
        border-color: $input-danger-border-color;
        box-shadow: $input-danger-border-color-focus-box-shadow;
    }

    .DropDown {
        box-shadow: $input-border-color-focus-box-shadow;
        font-family: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial, sans-serif;

        .Option {
            .option-with-description {
                line-height: 30px;

                .option-description {
                    font-size: 15px;
                    color: $light-font-color;
                }
            }

            .option-label {
                i {
                    margin-right: 8px;
                    border: 1px solid $font-color;
                    
                    &:not(.check) {
                        color: transparent;
                    }
                }
            }
        }        
    }
}