@import '../../Variables.scss';

.bg-grey {
    background: $border-color;
}

.error-page {   

    .error-page-code {
        display: flex;

        & div:nth-child(1){
            background-color: $primary-color;
        }

        & div:nth-child(2){
            background-color: $crif-color;
        }

        & div:nth-child(3){
            background-color: $font-color;
        }

        & div {            
            padding: 0vw 3vw;
            margin: 1vw;            

            & p {
                color: white;
                font-size: 6em;

                @media (min-width: $desktop) {
                    font-size: 8em;
                }
            }
        }
    }

    .error-page-message { 
        margin-top: 2vh;
        color: $font-color;
        font-size: 1.3em;
        text-align: center;

        @media (min-width: $desktop) {
            font-size: 1.5em;
        }
    }

    .error-page-buttons {
        margin-top: 2vh;
        display: flex;

        & a {
            width: 75%;
            margin: auto;            
            font-weight: 600;            

            &.is-crif {
                color: $crif-color;
                border-color: $crif-color;
                background: $border-color;

                &:hover {
                    color: $border-color;
                    background: $crif-color;
                }
            }

            @media (min-width: $desktop) {                
                margin-left: auto;
                margin-right: 0;
                width: auto;
            }
        }
    }
}