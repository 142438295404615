@import '../../../Variables.scss';
@import '../../../CommonStyle.scss';

.report-action-bar {
    width: 100%;
    min-height: $action-menu-height;
    height: $action-menu-height;
    background-color: #EFF6FC;
    margin: 0 !important;
    padding: 0 20px;
    color: $primary-color;

    .report-header {
        height: 100%;
        width: fit-content;
        display: inline-flex;

        .report-title {
            font-size: 1.5rem;
            font-weight: 600;
            margin: auto;
            margin-left: 1vw;
        }
    }   

    .action-button {
        @extend .noselect-text;
        
        padding: 0rem 1rem;
        height: 100%;
        display: flex;

        i {
            display: flex;

            &::before, &:before {
                margin: auto;
            }
        }

        span {
            margin: auto;
        }
    }
}

.report-dashboard {
    width: 100%;
    height: calc(100% - #{$action-menu-height});
}