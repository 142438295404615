@import "./BulmaOverrides.scss";
@import "node_modules/bulmaswatch/cosmo/bulmaswatch.scss";
@import "Variables.scss";
@import "./components/common/formikFields/FormikFields.scss";

.my-toast {
    &[class*="success"] {
        background-color: #40C5AF !important;
    }

    &[class*="warning"] {
        background-color: #FFC83D !important;
    }

    &[class*="error"] {
        background-color: #EF6950 !important;
    }

    &[class*="info"] {
        background-color: #B2B2B2 !important;
    }
}

.fa-2 {
    font-size: 2em;
}

.fa-3 {
    font-size: 4em;
}

.fa-4 {
    font-size: 7em;
}

.fa-5 {
    font-size: 12em;
}

.fa-6 {
    font-size: 20em;
}

.hide {
    display: none;
}

.display-flex {
    display: flex;
}

.width-85 {
    width: 85%;
}

html {
    overflow-y: auto !important;
}

html, body, #root {
    width: 100%;
    height: 100%;

    font-family: "wf_segoe-ui_normal", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
}

.react-contexify {
    z-index: 999999999;

    .react-contexify__item {
        i:first-child {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0.5em;
        }
    }
}

.hover-item {
    background-color: $hover-color;
}

.login-body {
    @extend .display-flex;
    width: 100%;
    height: 100%;
    background: url("./img/introback.png") no-repeat center center fixed;
    background-size: 100% 100%;
    position: relative;
}

div.input-group span.input-group-addon i.fa {
    position: absolute;
    left: 13px;
    top: 12px;
}

.size-whole-parent {
    width: 100%;
    height: 100%;
}

.middle-container {
    margin: auto;
}

.main-container {
    height: 100%;
    width: 100%;
}

.main-body {
    height: calc(100% - #{$top-navbar-height});
}

.tooltip {
    opacity: unset !important;
}

.spacer {
    clear: both;
}

.right {
    float: right;
}

.left {
    float: left;
}

.section{
    padding: 0.5rem .5rem;
    margin: 1.5rem 1rem;
    border: 1px solid #f1f1f1;
    border-left: 10px solid #f1f1f1;

    .section-title {
        margin-left: 2vw;
        margin-top: 1em;

        @media (max-width: $mobile) {
            text-align: center;
        }
    }
}

.delimiter {
    height: 2px;
    background-color: $border-color;
    margin: 1.5rem 0.5rem;
}

.table {
    background-color: transparent;
}

.table.no-border, 
.table.no-border td,
.table.no-border tr{
    border-width: 0;
}

.my-button {
    background-color: $inverse-primary-color;
    border-color: $primary-color;
    color: $primary-color;
    cursor: pointer;

    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $inverse-primary-color;
    }

    &:focus {
        box-shadow: unset !important;
        border-color: $primary-color !important;
        color: $inverse-primary-color !important; 
        background-color: $primary-color !important;
    }
}


.is-disabled {
    background-color: $inverse-primary-color !important;
    border-color: $disable-color !important;
    color: $disable-color !important;
    cursor: default;

    &:hover {
        background-color: $inverse-primary-color !important;
        border-color: $disable-color !important;
        color: $disable-color !important;
    }
}

.center {
    margin: auto !important;
}

.center-text {
    text-align: center;
}

.field-with-checkbox-80 {
    width: 80%;

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.buttons:last-child{
    margin-bottom: 0 !important;
}

.my-form {
    height: 100%;

    &.light {
        font-family: "wf_segoe-ui_light", "Segoe UI Light", "Segoe WP Light", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
    }
    
    .label {
        color: $font-color;
    }

    .header {
        padding: 0 40px;
        height: 14%;
        min-height: 100px;

        & ~ .form {
            height: 86%;
            max-height: calc(100% - 100px);
        }

        .buttons.top-buttons {
            padding: 0;
            height: auto;
        }
    }    

    .buttons {             
        &.top-buttons {
            height: 7%;
            margin-bottom: 0px;
            padding: 0 40px;

            & ~ .form {
                height: 93%;
            }
        }

        button.button {
            color: $font-color;
    
            &:hover, &:active, &:focus {
                background-color: inherit;
                box-shadow: unset;
            }
        }
    }   
    
    .form {
        height: 100%;

        .form-content {
            padding: 0 40px;
        }
    }
}

// scrollbar

.scrollbar-container.ps {
    .ps__rail-y, .ps__rail-x {
        z-index: 100;
    
        .ps__thumb-y, .ps__thumb-x{
            background-color: $primary-color;
            border-radius: 0;
        }
    }
} 

div {
    time:not(:first-child) {
        margin-left: 5px;
    }
}

.container-close {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3000 !important;
    cursor: auto !important;
}