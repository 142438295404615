@import "../Variables.scss";
@import "../CommonStyle.scss";

.navbar {
  padding: unset !important;
  background-color: $primary-color !important; 

  @media screen and (min-width: 1024px) {
    min-height: $top-navbar-height !important;
  }
}

.navbar .navbar-menu {
  background-color: transparent !important;
}

/* LOGO */

.navbar-brand {
  margin: 0 !important;
  padding: 0 !important;
}

.logo {
  height: 30px;  
}

.logo-small {
  height: 20px;  
}


.navbar-brand .navbar-item {
  padding: 2px;
}

.navbar-brand .navbar-item img {
  max-height: unset;
}

.navbar-dropdown {
  border: none !important;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(136,136,136,0.8) !important;
  -moz-box-shadow: 0px 0px 20px 0px rgba(136,136,136,0.8) !important;
  box-shadow: 0px 0px 20px 0px rgba(136,136,136,0.8) !important;
}

.app-list {
  width: 100%;
  margin: 0 !important;
}

.app-item {
  width: 100px;
  height: 100px;
  background-color: $primary-color !important; 
  padding: 0 !important;
  margin: 5px 1px 5px 1px;
  display: flex !important;
}

.app-item:first-child {
  margin-left: 12px;
}

.app-item:last-child {
  margin-right: 12px;
}

.hover-link {
  width: 100%;
  height: 100%;
  display: inherit;
  cursor: pointer;
}

.hover-link:hover {
  background-color:rgba(0,0,0,0.1) !important;
}

.app-list .app-link {
  margin: auto;
  color: white !important;
}

/* PROJECT */

.navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, 
div.navbar-item.is-active, div.navbar-item:focus, div.navbar-item:focus-within, div.navbar-item:hover {
  background-color: inherit !important;
}

.navbar-item.has-dropdown.is-active .navbar-link, .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link {
  background-color: inherit !important;
  background-color:rgba(0,0,0,0.1) !important;
}

.right {
  text-align: right;
}

.navbar-start * {
  color: white !important;
}

.navbar-start *::after {
  border-color: white !important;
}

.navbar-dropdown * {
  color: black !important;
}

.navbar-dropdown .navbar-item {
  @extend .noselect-text;

  &:hover {
    background-color: #eee !important;
    cursor: pointer;
  }
}

/* RIGHT */

.fa-cog, .fa-question {
  color: white;
  transform: scale(1.5,1.5); 
}

.settings-nav-icon {
  padding: 8px 14px !important;
}

.profil-dropdown {
  width: 275px;
}

.profil-detail-container {
  display: flex;
}

.profil-icon {
  border-radius: 50px;
  margin: auto;  
}

.profil-icon-navbar {
  transform: scale(2);
}

.profil-icon-detail {
  width: 40px;
  margin: 0 10px 5px 10px;
  max-height: none !important;
}

.profile-icon-container {
  width: 22%;
}

.profile-info-container {
  width: 78%;
}

.profil-info {
  font-size: 12px;
  color: gray !important;
}

.has-dropdown.is-right .navbar-dropdown {
  left: auto;
  right: 0;
}

.navbar-dropdown .navbar-item .fa {
  margin-right: 10px;
}

.font-blue {
  color: #0078d7 !important;
}

.navbar-item .navbar-link img {
  max-height: 23px;
}
.navbar:not(.is-transparent) .navbar-brand .navbar-item:hover{
  background-color: rgba(255, 255, 255, 0);
}

.navbar-end .navbar-link {
  padding: 0.5rem 1rem !important;
}