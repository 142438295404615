$crif-color: rgb(238,125,17);
$primary-color: #005a9e;
$primary: #005a9e; // override variable in bulma cosmo template
$primary-color-hover: #00477c;
$hover-color: #e2f2ff;
$selected-color: #f7f7f7;
$dropdown-hover-color: #eee;
$inverse-primary-color: #fff;
$disable-color: #dbdbdb;
$border-color: #ececec;
$input-border-color: #dbdbdb;
$input-border-color-hover:#b5b5b5;
$input-border-color-focus: #1faeff;
$input-border-color-focus-box-shadow: 0 0 0 0.125em rgba(31, 174, 255, 0.25);
$input-color: #333;
$input-danger-border-color: #FF2E12;
$input-danger-border-color-focus-box-shadow: 0 0 0 0.125em rgba(255, 46, 18, 0.25);
$font-color: #6c6c6c;
$light-font-color: #aaa;
$card-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
$action-color-icon: #0078D7;
$background-color: white;

$top-navbar-height: 3.25rem;
$action-menu-height: 40px;

$min-width-leftbar: 250px;

$mobile: 768px;
$tablet: 769px;
$desktop: 1024px;
$widescreen: 1216px;
$fullhd: 1408px;