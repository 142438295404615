@import '../../../Variables.scss';

div.work-container {
    margin: 0 !important;
    width: 100%;
    height: 100%;
}

.column.left-navbar-container {
    overflow: auto;
    padding: 0;
    border-right: 1px solid $border-color;
    color: $font-color;

    @media screen and (min-width: 769px) {
        min-width: $min-width-leftbar;
        flex: none;
    }

    .ps .ps__rail-y {
        z-index: 100;

        .ps__thumb-y{
            background-color: $primary-color;
            border-radius: 0;
        }
    }
}

.column.right-body-container {
    padding: 0;
    width: 500px;

    .action-bar {
        width: 100%;
        min-height: $action-menu-height;
        height: $action-menu-height;
        background-color: #EFF6FC;
        margin: 0 !important;
        padding: 0 20px;
        color: $font-color;
    }

    .dashboard {
        height: calc(100% - #{$action-menu-height});
        .dashboard-table {
            padding: 5px 20px;
        }
    }
    

    @media screen and (min-width: 769px) {
        //width: calc(100% - 200px);
        // flex: none;
    }
}

.page-loader-logo {
    height: 75px;
}

.pageloader{
    background-color: $primary-color !important;
}